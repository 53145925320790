<!--
 * @Author: zl
 * @Date: 2022-12-12 17:06:31
 * @LastEditTime: 2022-12-13 15:23:17
 * @LastEditors: zl
 * @Description: 运维操作日志
-->
<template>
	<div class="BasicDataIndex-layout filter-layout">
		<div class="filter-box pdt10 mb30">
			<el-form ref="searchForm"
					 :model="searchForm"
					 size="small"
					 label-width="100px"
					 v-show="filterShow">
				<el-row :gutter="10">
					<el-col :span="6">
						<el-form-item label="系统模块"
									  prop="moduleName">
							<el-select v-model="searchForm.moduleName"
									   placeholder="请选择系统模块">
								<el-option v-for="(item, index) in moduleNameList"
										   :key="index + 'moduleName'"
										   :label="item"
										   :value="item">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="操作类型"
									  prop="issues">
							<el-select v-model="searchForm.issues"
									   placeholder="请选择操作类型">
								<el-option v-for="(item, index) in operateTypeList"
										   :key="index + 'issues'"
										   :label="item"
										   :value="item">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="操作人员"
									  prop="operateUser">
							<el-input v-model="searchForm.operateUser"
									  placeholder="请输入操作人员"
									  clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="操作系统"
									  prop="operatSystem">
							<el-input v-model="searchForm.operatSystem"
									  placeholder="请输入操作系统"
									  clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="创建时间"
									  prop="time">
							<el-date-picker v-model="searchForm.time"
											type="daterange"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd HH:ss:mm"
											:default-time="['00:00:00', '23:59:59']"
											range-separator="~"
											start-placeholder="开始日期"
											end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>

					<el-col :span="18">
						<el-form-item class="text-right"
									  label-width="0">
							<el-button type="primary"
									   icon="el-icon-search"
									   @click="onSearch">查询</el-button>
							<el-button @click="onReset"
									   icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- 筛选折叠按钮 -->
			<div class="control-btn"
				 @click="filterShow = !filterShow">
				<span v-if="filterShow">
					点击收起
					<svg-icon icon-class="pack-up"
							  class="c-icon ml5"></svg-icon>
				</span>
				<span v-else>筛选
					<svg-icon icon-class="pack-down"
							  class="c-icon ml5"></svg-icon>
				</span>
			</div>
		</div>
		<div class="filter-content primary-layout">
			<div class="primary-header">
				<div class="left-text"></div>
				<div class="btnGroups">
					<el-button size="small"
							   @click="onExport">导出Excel</el-button>
					<el-button size="small"
							   class="ml10 btn-success"
							   icon="el-icon-refresh"
							   @click="onSearch"></el-button>
				</div>
			</div>
			<div class="primary-content">
				<div class="w-table-layout w-table-page">
					<div class="w-table-content">
						<vxe-table align="center"
								   auto-resize
								   border
								   :data="tableObj.data"
								   :loading="tableObj.loading"
								   highlight-hover-row
								   highlight-current-row
								   height="100%"
								   ref="logTable"
								   show-header-overflow="tooltip"
								   show-overflow="tooltip">
							<vxe-column title="序号"
										type="seq"
										width="65"></vxe-column>
							<vxe-column title="系统模块"
										field="moduleName"
										width="200px"></vxe-column>
							<vxe-column title="操作类型"
										field="issues"
										width="130px"></vxe-column>
							<vxe-column title="操作内容"
										field="content"
										min-width="200px"></vxe-column>
							<vxe-column title="操作人员"
										field="createUserName"
										width="150px"></vxe-column>
							<vxe-column title="主机"
										field="handleInsideIp"
										width="150px"></vxe-column>
							<vxe-column title="操作系统"
										field="operatSystem"
										width="130px"></vxe-column>
							<vxe-column title="创建时间"
										field="createDate"
										width="150px"></vxe-column>
							<vxe-column title="操作状态"
										field="progressStatusName"
										width="130px">
								<template #default="{ row }">
									<span :class="[row.status == 1 ? 'operating' : 'toRejected']">
										{{row.status == 1 ? '成功' : '失败'}}
									</span>
								</template>
							</vxe-column>
						</vxe-table>
					</div>
					<div class="w-table-pager">
						<vxe-pager :current-page="tableObj.currentPage"
								   :page-size="tableObj.pageSize"
								   :total="tableObj.total"
								   :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
								   align="center"
								   @page-change="handleCurrentChange"></vxe-pager>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import TextTooltip from '@/views/Common/TextTooltip'
import * as API from '@/api/home/homeIndex'
import { exportExcel } from 'utils/tools'
export default {
	name: 'BasicDataIndex',
	components: { TextTooltip },
	data() {
		return {
			filterShow: true,
			searchForm: {
				moduleName: '',
				issues: '',
				operateUser: '',
				operatSystem: '',
				time: [],
			},
			moduleNameList: [], // 模块名称
			operateTypeList: [], // 操作类型
			tableObj: {
				loading: false,
				data: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
			},
		}
	},
	created() {
		this.getSearchMaps()
		this.getTable()
	},
	methods: {
		// 表单搜索
		onSearch() {
			this.tableObj.currentPage = 1
			this.getTable()
		},

		// 重置表单
		onReset() {
			this.$refs.searchForm.resetFields()
			this.onSearch()
		},

		// 获取查询条件
		getSearchMaps() {
			API.getMaps().then((res) => {
				if (res.data) {
					this.moduleNameList = res.data.moduleName
					this.operateTypeList = res.data.operateType
				}
			})
		},

		// 获取表格数据
		getTable() {
			let params = {
				...this.searchForm,
				startTime:
					this.searchForm.time.length > 0
						? this.searchForm.time[0]
						: '',
				endTime:
					this.searchForm.time.length > 1
						? this.searchForm.time[1]
						: '',
				size: this.tableObj.pageSize,
				current: this.tableObj.currentPage,
			}
			delete params.time
			this.tableObj.loading = true
			API.getMaintenanceOperateRecordList(params)
				.then((res) => {
					this.tableObj.data = res.data.records
					this.tableObj.total = Number(res.data.total)
					this.tableObj.loading = false
				})
				.catch(() => {
					this.tableObj.loading = false
				})
		},

		// 分页
		handleCurrentChange({ currentPage, pageSize }) {
			this.tableObj.currentPage = currentPage
			this.tableObj.pageSize = pageSize
			this.getTable()
		},

		// 导出excel
		onExport() {
			let params = {
				current: 1,
				size: this.tableObj.total,
			}
			let url =
				process.env.VUE_APP_BASE_URL +
				'/sysMaintenanceOperateRecord/exportOperateRecordList'
			let fileName = ''
			exportExcel(url, params, fileName)
		},
	},
	computed: {
		...mapState('dict', {
			projectDict: (state) => state.projectDict,
		}),
		...mapState('menu', {
			roleBtns: (state) => state.roleBtns,
		}),
	},
}
</script>
<style lang='less' scoped>
.BasicDataIndex-layout {
	height: 100%;
}
</style>